import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import InputField from "./CustomInputField"
import InstallPopup from "./InstallPopup"
import ICLLogo from "../images/icl_logo.png"
import newsletterImage from "../images/newsletter-image.jpg"
import axios from "axios"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        menu(id: "footer", idType: NAME) {
          menuItems {
            nodes {
              databaseId
              label
              cssClasses
              order
              path
              target
            }
          }
        }
        gravityFormsForm(id: "R3Jhdml0eUZvcm1zRm9ybToy") {
					fields {
					  nodes {
					    ... on WP_EmailField {
					      id
					      isRequired
					      label
					      type
					    }
					  }
			    }
			  }
      }
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const { menuItems } = data.wp.menu
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  
  const handleNewsletterSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    // Convert FormData into JSON
    var dataObj = {
      form_id: "2",
      baseUrl: "https://osmocoteapp.revhub.io/wp-json/gf/v2",
    }

    formData.forEach(function (value, key) {
      dataObj[key] = value
    })
    var jsonData = JSON.stringify(dataObj)

    try {
      setIsError(false)
      setIsLoading(true)
      const result = await axios({
        method: "POST",
        url: "/.netlify/functions/newGfEntry",
        data: jsonData,
      })
        .then(function (response) {
          setIsLoading(false)
          setIsSuccess(true)
          window.dataLayer = window.dataLayer || []
		  window.dataLayer.push({
		    event: 'tba.form.confirmation',
		    eventData: {
		      formTitle: 'Newsletter Signup',
		      formLocation: 'footer'
		    }
		  })
        })
        .catch(function (error) {
          setIsLoading(false)
          setIsSuccess(false)
          setIsError(true)
        })
    } catch (error) {
      setIsLoading(false)
      setIsError(true)
    }
  }
  
  return (
    <footer
      id="colophon"
      className="site-footer border-light border-top pt-4 mt-4 pt-md-5 mt-md-5"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            {/* <Link to="/"> */ }
              <img
	              src={newsletterImage}
	              alt="ICL The Hort Factor"
	              className="img-fluid mb-0"
			  />
              <span className="sr-only">{data.site.siteMetadata.title}</span>
            {/* </Link> */}
            <p>
              Join our newsletter to keep up with ICL happenings and horticulture products.
            </p>
            {isError && (
			        <div className="alert alert-danger form-error">
			          Sorry, an unexpected error occurred. Please try again.
			        </div>
			      )}
			      {isSuccess ? (
			        <div className="alert alert-success form-success">
			          Thanks for subscribing!
			        </div>
			      ) : (
			        <form
			          onSubmit={e => handleNewsletterSubmit(e)}
			          className={`row no-gutters ${isLoading && 'form-loading'}`}
			        >
			          {data.wp.gravityFormsForm.fields.nodes.map((field, i) =>
			            (
				            <div key={i} className="col-8 pr-3">
				              <InputField
				                name={`input_${field.id}`}
				                type={field.type}
				                label={field.label}
				                required={field.isRequired}
				                key={field.type + field.id}
				              />
			              </div>
			            )
			          )}
			          <div className="col-4 mb-3">
				          <button className="btn btn-icl btn-block btn-skew h-100 ml-n5" type="submit">
				            <span>Subscribe</span>
				          </button>
								</div>
			          {isLoading && (
			            <div className="loading-rings">
			              <div></div>
			              <div></div>
			              <div></div>
			              <div></div>
			            </div>
			          )}
			        </form>
			      )}
			      {/*}
            <form className="row no-gutters">
              <div className="col-8 pr-3">
                <InputField
                  name="footer-email"
                  type="email"
                  label="Email Address"
                />
              </div>
              <div className="col-4 mb-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-block h-100 "
                >
                  Subscribe
                </button>
              </div>
            </form>
            */}
          </div>
          <div className="col-12 col-md-6">
            <ul className="footer-navigation list-unstyled row mb-0 mt-4 mt-md-0">
              {menuItems.nodes.map(link => {
                return (
                  <li key={link.databaseId} className="col-6">
                    <Link
                      to={link.path}
                      activeClassName="active"
                      className="nav-link px-0 px-lg-3 text-dark"
                    >
                      {link.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright text-center border-light border-top py-4 mt-4 py-md-5 mt-md-5">
        <div className="container">
          <p>
            <img
              src={ICLLogo}
              width="122"
              height="30"
              className="img-fluid mb-0"
              alt="The Hort Factor - Turf &amp; Ornamental quarterly newsletter"
            />
          </p>
          <p className="small mb-1"><Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-and-conditions">Terms and Conditions</Link></p>
          <p className="m-0">
            <small>&copy; {new Date().getFullYear()} ICL. All rights reserved.</small>
          </p>
        </div>
      </div>
      <InstallPopup />
    </footer>
  )
}

export default Footer
